import React from "react";
import { useTranslation } from "react-i18next";

import amenitiesDivider from "../images/amenitiesDivider.svg";

// Icons
import BeerIcon from "../images/icons/beer.svg";
import CoffeeIcon from "../images/icons/coffee.svg";
import LockerIcon from "../images/icons/lockers.svg";
import RacquetIcon from "../images/icons/racquet.svg";
import PingPongIcon from "../images/icons/pingpong.svg";
import ShowerIcon from "../images/icons/shower.svg";
import ShopIcon from "../images/icons/shop.svg";

export default function Amenities() {
  const { t } = useTranslation();
  return (
    <section
      id="about"
      className="amenities d-flex flex-column flex-md-row justify-content-between align-items-center gap-3"
      aria-labelledby="amenities-heading"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      viewport={{ once: true }}
    >
      <div className="image-container col-5">
        <img
          src={amenitiesDivider}
          alt="Illustration depicting amenities offered"
          className="img-fluid divider"
          loading="lazy"
        />
      </div>
      <div className="amenities-content d-flex flex-column align-items-center align-items-md-start gap-3 col-md-7 container-sm py-5">
        <div className="d-flex flex-column justify-content-between align-items-md-start align-items-center">
          <h4 id="amenities-heading">{t("about1")}</h4>
          <h1 className="text-white">Padel Alley</h1>
          <h4 id="amenities-heading">
            {t("amenities1")} {t("amenities2")}
          </h4>
        </div>
        <p className="text-white text-center text-md-start description">
          {t("amenitiesDescription")}
        </p>
        <ul
          className="amenities-list text-white container"
          aria-labelledby="amenities-heading"
        >
          <div className="row">
            <div className="offset-1 col-5 offset-md-0 col-md-6">
              <li className="text-center text-md-start mb-3">
                <img src={RacquetIcon} className="icon" alt="Racquet icon" />
                {t("amenitiesItem1")}
              </li>
              <li className="text-center text-md-start mb-3">
                <img src={PingPongIcon} className="icon" alt="Ping Pong icon" />
                {t("amenitiesItem2")}
              </li>
              <li className="text-center text-md-start mb-3">
                <img src={BeerIcon} className="icon" alt="Beer icon" />
                {t("amenitiesItem3")}
              </li>
              <li className="text-center text-md-start mb-3">
                <img src={CoffeeIcon} className="icon" alt="Coffee icon" />
                {t("amenitiesItem4")}
              </li>
            </div>
            <div class="offset-1 col-5 offset-md-0 col-md-6">
              <li className="text-center text-md-start mb-3">
                <img src={ShowerIcon} className="icon" alt="Shower icon" />
                {t("amenitiesItem5")}
              </li>
              <li className="text-center text-md-start mb-3">
                <img src={ShopIcon} className="icon" alt="Shop icon" />
                {t("amenitiesItem6")}
              </li>
              <li className="text-center text-md-start mb-3">
                <img src={LockerIcon} className="icon" alt="Locker icon" />
                {t("amenitiesItem7")}
              </li>
            </div>
          </div>
        </ul>
        <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start gap-3 justify-content-center">
          <button
            className="btn"
            type="button"
            onClick={() =>
              window.open(
                "https://playtomic.io/tenant/e979bfac-bfbe-4cba-8cd9-90f504c4b08a",
                "_blank"
              )
            }
            aria-label="Go to Playtomic"
            title="Go to Contact Us section"
          >
            {t("reserveNow")}
          </button>
          <button
            className="btn d-flex gap-2 bg-green"
            type="button"
            onClick={() =>
              window.open("https://chat.whatsapp.com/CjaPQRgQ9k09MmUeLfWJOS")
            }
            aria-label="Go to WhatsApp group"
            title="Go to WhatsApp group"
          >
            <i className="bi bi-whatsapp" aria-hidden="true" />
            {t("joinOurCommunity")}
          </button>
        </div>
      </div>
    </section>
  );
}
