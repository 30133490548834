import React, { useState, useEffect } from "react";

// Sections
import Navbar from "./sections/Navbar";
import Hero from "./sections/Hero";
import Amenities from "./sections/Amenities";
import Instagram from "./sections/Instagram";
import Academy from "./sections/Academy";
import Icons from "./sections/Icons";
import OpenPlay from "./sections/OpenPlay";
import Tutorial from "./sections/Tutorial";
import Calendar from "./sections/Calendar";
import Footer from "./sections/Footer";
import ComingSoon from "./sections/ComingSoon";

import logoWatermark from "./images/logoWatermark2.svg";
import Hero1 from "./images/hero1.webp";
import Hero2 from "./images/hero2.webp";
import Hero3 from "./images/hero3.webp";
import Hero4 from "./images/hero4.webp";

const heroImages = [Hero1, Hero2, Hero3, Hero4];

export default function App() {
  const isComingSoon = false;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fade, setFade] = useState(false);

  useEffect(() => {
    // Preload all hero images
    heroImages.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setCurrentImageIndex((prev) => (prev + 1) % heroImages.length);
        setFade(false);
      }, 1000); // match with CSS transition
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  if (isComingSoon) {
    return <ComingSoon />;
  }

  return (
    <main className="App">
      <Navbar />
      <div
        className={`top-bg bg-opacity-25 ${fade ? "fade-out" : ""}`}
        style={{ backgroundImage: `url(${heroImages[currentImageIndex]})` }}
      >
        <div className="overlay"></div>
        <Hero />
      </div>
      <div className="amenities-bg">
        <Amenities />
        <img
          src={logoWatermark}
          alt="Tennis Ball Watermark"
          className="watermark img-fluid"
        />
      </div>
      <Instagram />
      <Academy />
      <Icons />
      <OpenPlay />
      <Tutorial />
      <Calendar />
      <Footer />
    </main>
  );
}
