import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function Hero() {
  const { t } = useTranslation();
  return (
    <motion.header
      className="w-100 container d-flex flex-column justify-content-end align-content-center gap-3 pb-5"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      viewport={{ once: true }}
    >
      <div className="d-flex flex-column justify-content-center align-items-center pb-5">
        <h3 className="text-center">{t("hero1")}</h3>
        <h1 className="text-center">{t("hero2")}</h1>
        <h3 className="text-center">{t("hero3")}</h3>
      </div>
      <button
        className="btn align-self-center"
        type="button"
        onClick={() =>
          window.open(
            "https://playtomic.io/tenant/e979bfac-bfbe-4cba-8cd9-90f504c4b08a",
            "_blank"
          )
        }
        aria-label="Go to Playtomic"
      >
        {t("reserveNow")}
      </button>
    </motion.header>
  );
}
