import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function OpenPlay() {
  const { t } = useTranslation();
  return (
    <motion.section
      id="openPlay"
      className="openplay-bg py-5 px-3 px-md-5 d-flex justify-content-center align-items-center"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      viewport={{ once: true }}
    >
      <div className="container bg-white py-5 px-md-5 d-flex flex-column justify-content-center align-items-center gap-3">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h4 className="text-center m-0">{t("openPlay1")}</h4>
          <h1 className="text-center">{t("openPlay2")}</h1>
        </div>

        <p className="description text-center">{t("openPlayDescription")}</p>
        <button
          className="btn"
          type="button"
          onClick={() =>
            window.open(
              "https://playtomic.io/tenant/e979bfac-bfbe-4cba-8cd9-90f504c4b08a",
              "_blank"
            )
          }
          aria-label="Go to Playtomic"
        >
          {t("reserveNow")}
        </button>
      </div>
    </motion.section>
  );
}
